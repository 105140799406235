import { Box, Flex, HStack, Image, LinkBox, Text } from "@chakra-ui/react"
import Link from "next/link"
import { CloudImages } from "lib/cosmic/images"
import { BreadcrumbDivider } from "lib/wayfinding"

import TertiaryButton from "../Buttons/Tertiary"
import AccountNav from "./AccountNav"

const HomeTopNav = ({ name, organizationName, pageName }: { name: string; organizationName: string; pageName?: string }) => {
  return (
    <Box backgroundImage="linear-gradient(90deg, #171717 0%, #1E112D 100%)" px="40px" py="28px">
      {/* 77px doesn't fit in our 4px grid but it looks right when switching betweeen home <--> workflow pages */}
      <Flex align="center" justifyContent="space-between" margin="auto">
        <HStack spacing="80px">
          <Link href="/app">
            <Image src={CloudImages.LOGO_WHITE} alt="logo" height="32px" pointerEvents="none" userSelect="none" />
          </Link>
          <HStack display="flex" spacing="4px">
            {pageName ? (
              <>
                <LinkBox>
                  <Link href="/app" aria-label="All datasets">
                    <TertiaryButton
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(140, 76, 205, 0.2)",
                        },
                      }}
                      color="white"
                      fontWeight="500"
                      padding="8px"
                      height="auto"
                      fontSize="16px"
                    >
                      {organizationName}
                    </TertiaryButton>
                  </Link>
                </LinkBox>

                <Text mr="4px" fontWeight="500" color="gray">
                  {BreadcrumbDivider}
                </Text>
                <Text fontWeight="500" color="white">
                  {pageName}
                </Text>
              </>
            ) : (
              <Text padding="8px" fontWeight="500" color="white">
                {organizationName}
              </Text>
            )}
          </HStack>
        </HStack>
        <AccountNav name={name} color="white" />
      </Flex>
    </Box>
  )
}

export default HomeTopNav
