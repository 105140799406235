import React from "react"

import { useDropzone } from "react-dropzone"
import { Flex, Box, Text } from "@chakra-ui/react"
import { FiFilePlus } from "react-icons/fi"
import { DEFAULT_ALLOWED_FILE_TYPES, FileType } from "lib/files"

import { H4 } from "../Text"

type FilePickerMask = {
  [key: string]: string[]
}

export const getFilePickerMask = (allowedTypes: FileType[]): FilePickerMask => {
  return allowedTypes.reduce(
    (acc, fileType) => {
      const mimeTypes = MIME_TYPES[fileType]
      const extension = `.${fileType.toLowerCase()}`

      mimeTypes.forEach((mimeType) => {
        acc[mimeType] = [extension]
      })

      return acc
    },
    {} as Record<string, string[]>,
  )
}

export const MIME_TYPES: Record<FileType, string[]> = {
  [FileType.PDF]: ["application/pdf", "application/x-pdf"],
  [FileType.DOCX]: ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/x-vnd.openxmlformats-officedocument.wordprocessingml.document"],
  [FileType.DOC]: ["application/msword", "application/x-msword"],
  [FileType.PNG]: ["image/png", "image/x-png"],
  [FileType.CSV]: ["text/csv", "text/x-csv", "application/csv", "application/x-csv"],
  [FileType.XLSX]: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/x-vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  [FileType.JPG]: ["image/jpeg", "image/x-jpeg"],
  [FileType.JPEG]: ["image/jpeg", "image/x-jpeg"],
  [FileType.JSON]: ["application/json", "text/json", "application/x-json"],
  [FileType.SVG]: ["image/svg+xml", "image/x-svg"],
  [FileType.WEBP]: ["image/webp", "image/x-webp"],
  [FileType.HEIC]: ["image/heic", "image/x-heic"],
}

export const DEFAULT_DOCUMENT_FILE_PICKER_MASK = getFilePickerMask(DEFAULT_ALLOWED_FILE_TYPES)

const DefaultDropzoneText = () => {
  return (
    <Text>
      Drag and drop or <span style={{ color: "var(--secondary)" }}>click</span> to upload file
    </Text>
  )
}

export const FileDropzone = ({
  onDrop,
  height = "",
  warningText,
  filePickerMask,
  dropzoneText,
}: {
  onDrop: (acceptedFiles: File[]) => void
  height?: string
  warningText?: JSX.Element
  filePickerMask: FilePickerMask
  dropzoneText?: JSX.Element
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: filePickerMask,
  })

  return (
    <Flex
      {...getRootProps({})}
      justify="center"
      align="center"
      height={height || "140px"}
      width="100%"
      padding="5px"
      border="1px"
      borderRadius="8px"
      backgroundColor="white"
      borderColor="var(--card-border)"
      borderStyle="dashed"
      outline="none"
      transition="border .24s ease-in-out"
      fontSize=".85rem"
      fontWeight="bold"
      cursor="pointer"
    >
      <input {...getInputProps()} />
      <Flex flexDirection="column" align="center">
        <Box marginBottom="8px">
          <FiFilePlus fontSize="16px" color="grey" />
        </Box>

        {isDragActive ? (
          <H4 color="grey">Drop file here</H4>
        ) : (
          <H4
            color="grey"
            style={{
              textAlign: "center",
            }}
          >
            {dropzoneText || <DefaultDropzoneText />}
            {warningText && (
              <Box fontSize="14px" textAlign="center" marginTop="8px">
                {warningText}
              </Box>
            )}
          </H4>
        )}
      </Flex>
    </Flex>
  )
}
