import { useEffect } from "react"

import { signOut, useSession } from "next-auth/react"
import { useRouter } from "next/router"
import * as Sentry from "@sentry/nextjs"
import mixpanel from "mixpanel-browser"
import { H } from "@highlight-run/next/client"
import { useAppDispatch, useAppSelector } from "src/store/hooks"
import { clearUser, selectUser, setUser } from "src/store/slices/userSlice"
import { getUserById } from "pages/api/models/users/queries/getUserById"
import { getWorkflowsByUser } from "pages/api/models/workflows/queries/getWorkflowsByUser"
import { getMixpanelProjectId } from "lib/clients/mixpanel"

import { useToastContext } from "./useToastContext"
import useGetWorkflowByUuid from "./getWorkflows/useGetWorkflowByUuid"

export default function useAppMountLogic() {
  const { data: session, status } = useSession({ required: true })
  const router = useRouter()
  const dispatch = useAppDispatch()

  const { workflowUuid } = router.query as { workflowUuid: string }

  const activeUser = useAppSelector(selectUser)

  const { workflow } = useGetWorkflowByUuid({ workflowUuid: workflowUuid ?? "" }, "app:getWorkflow")
  const activeWorkflowUuid = workflow?.uuid
  const activeWorkflowId = workflow?.id

  useEffect(() => {
    const handleRouteChange = async (url) => {
      // Not using the router object here because it is populated async, and might not be updated immediately when the route changes.
      const queryWorkflowUuid = new URL(url, window.location.href).searchParams.get("workflow")

      if (!queryWorkflowUuid || queryWorkflowUuid === activeWorkflowUuid) {
        return
      }

      const workflows = await getWorkflowsByUser({ userId: activeUser?.id })
      const relevantWorkflow = workflows.find((workflow) => workflow.uuid === queryWorkflowUuid)

      if (!relevantWorkflow) {
        Sentry.captureException(`Workflow with UUID ${queryWorkflowUuid} not found`)
        return
      }
    }

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router, activeWorkflowUuid, activeUser, dispatch])

  const { showError } = useToastContext()

  const setUpUser = async () => {
    if (status === "loading" || activeUser) {
      return
    }

    try {
      const user = await getUserById({ id: session.user.id })

      if (user) {
        dispatch(setUser(user))
      } else {
        showError("Logging you out...")
        dispatch(clearUser())
        await signOut({ callbackUrl: "/auth/login" })
      }
    } catch (err) {
      Sentry.captureException(err)
      showError("Unable to find selected user.")
    }
  }

  const initMixPanel = () => {
    mixpanel.init(getMixpanelProjectId(), {
      debug: process.env.NODE_ENV === "development",
      track_pageview: true,
      persistence: "localStorage",
    })

    if (activeUser) {
      // identify mixpanel users
      try {
        mixpanel.identify(activeUser.id)
        mixpanel.people.set({
          $email: activeUser.email,
          $name: activeUser.name,
        })
      } catch (error) {
        console.info(error)
        Sentry.captureException(error)
      }

      // identify highlight users
      try {
        H.identify(activeUser.email, {
          id: activeUser.id,
          name: activeUser.name,
        })
      } catch (error) {
        console.info(error)
        Sentry.captureException(error)
      }
    }
  }

  const appState = {
    activeUser,
    session,
    status,
    router,
    activeWorkflowId,
    setUpUser,
    dispatch,
    initMixPanel,
  }

  return appState
}
