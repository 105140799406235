import useSWR from "swr"
import { getFeatureFlagState } from "pages/api/models/featureFlags/queries/getFeatureFlagState"

import { cacheKey, returnedQuery } from "../types"

export default function useGetFeatureFlagState(cacheKey: cacheKey): returnedQuery {
  const { data, error, isLoading } = useSWR([cacheKey], () => {
    return getFeatureFlagState()
  })

  return {
    data,
    isLoading,
    isError: !!error,
    error,
  }
}
