import useSWR from "swr"
import { getWorkflowByUuid, QueryParams } from "pages/api/models/workflows/queries/getWorkflowByUuid"

import { cacheKey, returnedQuery } from "../types"

export default function useGetWorkflow(params: QueryParams, cacheKey: cacheKey): returnedQuery {
  const { data, error, isLoading } = useSWR([params, cacheKey], ([params]) => {
    if (!params.workflowUuid) {
      return
    }

    return getWorkflowByUuid(params)
  })

  return {
    workflow: data,
    isLoading,
    isError: !!error,
    error,
  }
}
