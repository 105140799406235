import React, { useEffect } from "react"

import * as Sentry from "@sentry/nextjs"
import { feedbackIntegration } from "@sentry/browser"
import { FiMessageSquare } from "react-icons/fi"
import { User } from "@prisma/client"
import { useAppSelector } from "src/store/hooks"
import { selectUser } from "src/store/slices/userSlice"

import SecondaryButton from "../Buttons/Secondary"

const FeedbackWidget = () => {
  const activeUser = useAppSelector(selectUser) as User

  useEffect(() => {
    if (activeUser) {
      Sentry.setUser({
        name: activeUser?.name,
        email: activeUser?.email,
      })
    }
  }, [activeUser])

  const handleClick = async () => {
    const feedback = feedbackIntegration({
      colorScheme: "system",
      formTitle: "Give Platform Feedback",
      submitButtonLabel: "Send Feedback",
      useSentryUser: {
        email: "email",
        name: "name",
      },
      showName: false,
      showEmail: false,
      messagePlaceholder: "What do you like or dislike about the platform?",
    })

    const form = await feedback.createForm({})

    form.appendToDom()
    form.open()
  }

  return (
    <SecondaryButton onClick={handleClick} position="fixed" bottom={4} right={4}>
      <FiMessageSquare
        style={{
          marginRight: "8px",
        }}
      />
      Feedback
    </SecondaryButton>
  )
}

export default FeedbackWidget
