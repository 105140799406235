import * as pluralize from "pluralize"

export const maxFileSizeBytes = 15_000_000
export const maxFilesPerUpload = 5

export enum FileType {
  PDF = "pdf",
  DOCX = "docx",
  DOC = "doc",
  PNG = "png",
  CSV = "csv",
  XLSX = "xlsx",
  JPG = "jpg",
  JPEG = "jpeg",
  JSON = "json",
  SVG = "svg",
  WEBP = "webp",
  HEIC = "heic",
}

export const DEFAULT_ALLOWED_FILE_TYPES = [FileType.PDF, FileType.DOCX, FileType.DOC, FileType.PNG, FileType.CSV, FileType.XLSX, FileType.JSON]

export const ALL_SUPPORTED_FILE_TYPES = Object.values(FileType)

const SUPPORTED_IMAGE_FILE_TYPES = [FileType.PNG, FileType.JPEG, FileType.JPG, FileType.SVG, FileType.WEBP, FileType.HEIC]

export const humanizeSupportedFileTypes = (fileTypes: FileType[]): string => {
  return fileTypes.join(", ")
}

export const isSupportedFileType = (file: any, allowedFileTypes: FileType[]): boolean => {
  const extension = file.originalname.split(".").pop().toLowerCase()

  return allowedFileTypes.includes(extension)
}

export const isSupportedImageFileType = (file: File): boolean => {
  return isSupportedFileType(file, SUPPORTED_IMAGE_FILE_TYPES)
}

const getFileTypeFromExtension = (extension: string): FileType | undefined => {
  const normalizedExtension = extension.toLowerCase()

  return Object.values(FileType).find((type) => type === normalizedExtension)
}

export const validateFile = (file: any): string | null => {
  if (!file) {
    return "File is required"
  }

  const fileExtension = file.name.split(".").at(-1).toLowerCase()
  const fileType = getFileTypeFromExtension(fileExtension)

  if (!fileType || !ALL_SUPPORTED_FILE_TYPES.includes(fileType)) {
    return `File type is not supported. Supported file types are: ${ALL_SUPPORTED_FILE_TYPES.join(", ")}`
  }

  return null
}

export const validateMultipleFiles = (existingFileCount: number, files: File[]): string[] | null => {
  const totalFileCount = existingFileCount + files.length

  if (totalFileCount > maxFilesPerUpload) {
    return [`You can only upload ${maxFilesPerUpload} ${pluralize("file", maxFilesPerUpload)} at a time`]
  }

  return files.reduce((acc, file) => {
    const error = validateFile(file)

    if (error) {
      acc.push(error)
    }
    return acc
  }, [] as string[])
}

export const getFileBaseName = (file: File) => {
  return file.name.split(".").slice(0, -1).join(".")
}
