import { Suspense, useEffect } from "react"

import Head from "next/head"
import { Box } from "@chakra-ui/react"
import FallbackLoadingAnimation from "src/components/Loading/FallbackLoadingAnimation"
import useGetFeatureFlagState from "src/hooks/getFeatureFlags/useGetFeatureFlagState"
import useAppMountLogic from "src/hooks/useAppMount"
import FeedbackWidget from "src/components/UserInteractable/FeedbackWidget"
import HomeTopNav from "src/components/navigation/HomeTopNav"
import useGetOrganizationById from "src/hooks/getOrganizations/useGetOrganizationById"

const HomeLayout = ({ children, pageName }: { children: React.ReactNode; pageName?: string }) => {
  const { data: flagState } = useGetFeatureFlagState("app:getFeatureFlagState")

  const { activeUser, session, status, setUpUser, initMixPanel } = useAppMountLogic()

  useEffect(() => {
    if (activeUser) {
      initMixPanel()
      return
    }

    void setUpUser()
  }, [activeUser, session, status, initMixPanel, setUpUser])

  const { organization } = useGetOrganizationById({ id: activeUser?.organizationId || -1 }, "getOrganization")

  if (status === "loading") {
    return <FallbackLoadingAnimation />
  }

  if (flagState && flagState.state["maintenance-mode"]) {
    return <Box p="32px">The site is currently under maintenance. Please check back in 30 mins. Our apologies for the inconvenience.</Box>
  }

  return (
    <>
      <Head>
        <title>Integral - Home</title>
      </Head>
      <HomeTopNav name={activeUser?.name || ""} organizationName={organization?.name} pageName={pageName} />

      <Suspense fallback={<FallbackLoadingAnimation />}>{children}</Suspense>
      {flagState?.state["feedback-widget-may-15"] && <FeedbackWidget />}
    </>
  )
}

export default HomeLayout
